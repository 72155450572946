import { useState } from 'react'

// Adapted from https://usehooks.com/useLocalStorage/

export default function useSessionStorage(key, initialValue = '') {
  // Use lazy initial state by passing function to useState;
  // it is only called on the initial component render
  const [storedValue, setStoredValue] = useState(() => {
    const item = sessionStorage.getItem(key)
    return item ? JSON.parse(item) : initialValue
  })

  // We need to return a wrapped version of setStoredValue
  // which writes the value to store into session storage
  function setValue(value) {
    const valueToStore = value instanceof Function ? value(storedValue) : value
    setStoredValue(valueToStore)
    sessionStorage.setItem(key, JSON.stringify(valueToStore))
  }

  return [storedValue, setValue]
}
