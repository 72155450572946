import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import * as R from 'ramda'
import moment from 'moment'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { BackgroundImage, LoadingButton } from 'common/components'

import Autocomplete from '@material-ui/lab/Autocomplete'
import ascByName from 'common/utils/sort'

import background from 'assets/94772307-doctor-in-hospital-background-with-copy-space-healthcare-and-medical-concept-.png'
import logo from 'assets/TeleMeditar_Logo.png'

import useSessionStorage from 'common/utils/useSessionStorage'
import USER_SESSION_KEY from 'common/utils/constants'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  fullWidth: {
    width: '100%',
  },
}))

const Request = () => {
  const classes = useStyles()
  const [specialties, setSpecialties] = useState(null)
  const [providers, setProviders] = useState(null)
  const [delegations, setDelegations] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [sessionUser] = useSessionStorage(USER_SESSION_KEY)

  useEffect(() => {
    if (!sessionUser) window.location.href = '/login'
  }, [])

  const schema = yup.object().shape({
    name: yup.string().required('Este campo es requerido'),
    lastName: yup.string().required('Este campo es requerido'),
    document: yup.string().required('Este campo es requerido'),
    specialty: yup.object().required('Este campo es requerido').nullable(),
    provider: yup.object().required('Este campo es requerido').nullable(),
    delegation: yup.object().required('Este campo es requerido').nullable(),
    address1: yup.string().required('Este campo es requerido'),
    address2: yup.string(),
    district: yup.string().required('Este campo es requerido'),
    zipcode: yup.string().required('Este campo es requerido'),
    phone: yup.string(),
    cellphone: yup.string(),
  })

  const {
    control,
    errors,
    handleSubmit,
    triggerValidation,
    register,
    setValue,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      name: '',
      lastName: '',
      document: '',
      specialty: null,
      provider: null,
      delegation: null,
      address1: '',
      address2: '',
      district: '',
      zipcode: '',
      phone: '',
      cellphone: '',
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    register('specialty')
    register('provider')
    register('delegation')
  }, [register])

  const watchProvider = watch('provider')
  const watchDelegation = watch('delegation')
  const watchSpecialty = watch('specialty')

  useEffect(() => {
    if (!watchSpecialty) return
    setProviders(null)
    axios(
      `${process.env.REACT_APP_API_URL}/provider/specialist?specialtyid=${watchSpecialty.id}`,
      {
        method: 'GET',
      }
    ).then((response) => {
      setProviders(ascByName(response.data.items))
    })
  }, [watchSpecialty])

  const onSubmit = (data) => {
    setLoading(true)
    setError(null)
    axios(`${process.env.REACT_APP_API_URL}/appointment/request`, {
      method: 'POST',
      data: {
        ...data,
        delegation: data.delegation.id,
        provider: data.provider.id,
        specialty: data.specialty.id,
      },
    })
      .then(() => {
        setSuccess(true)
        reset()
        setProviders(null)
        if (sessionUser.delegation)
          setValue(
            'delegation',
            R.find(R.propEq('id', sessionUser.delegation), delegations),
            true
          )
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    axios(`${process.env.REACT_APP_API_URL}/specialty`, {
      method: 'GET',
    }).then((response) => {
      setSpecialties(ascByName(response.data.items))
    })

    axios(`${process.env.REACT_APP_API_URL}/delegation`, {
      method: 'GET',
    }).then((response) => {
      setDelegations(ascByName(response.data.items))
      if (sessionUser.delegation)
        setValue(
          'delegation',
          R.find(R.propEq('id', sessionUser.delegation), response.data.items),
          true
        )
    })
  }, [])

  return (
    <BackgroundImage image={background}>
      <Grid container className={classes.container}>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          <Card className={classes.card}>
            <CardMedia className={classes.logo} image={logo} />
            <CardContent className={classes.cardContent}>
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                autoComplete='off'
              >
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='name'
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                    label='Nombre'
                    onBlur={() => triggerValidation('name')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='lastName'
                    error={!!errors.lastName}
                    helperText={errors.lastName && errors.lastName.message}
                    label='Apellido'
                    onBlur={() => triggerValidation('lastName')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='document'
                    error={!!errors.document}
                    helperText={errors.document && errors.document.message}
                    label='Nro Documento'
                    onBlur={() => triggerValidation('document')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='address1'
                    error={!!errors.address1}
                    helperText={errors.address1 && errors.address1.message}
                    label='Dirección'
                    onBlur={() => triggerValidation('address1')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='address2'
                    error={!!errors.address2}
                    helperText={errors.address2 && errors.address2.message}
                    label='Dirección (más información)'
                    onBlur={() => triggerValidation('address2')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='district'
                    error={!!errors.district}
                    helperText={errors.district && errors.district.message}
                    label='Localidad'
                    onBlur={() => triggerValidation('district')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='zipcode'
                    error={!!errors.zipcode}
                    helperText={errors.zipcode && errors.zipcode.message}
                    label='Código Postal'
                    onBlur={() => triggerValidation('zipcode')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='phone'
                    error={!!errors.phone}
                    helperText={errors.phone && errors.phone.message}
                    label='Teléfono de Casa'
                    onBlur={() => triggerValidation('phone')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='cellphone'
                    error={!!errors.cellphone}
                    helperText={errors.cellphone && errors.cellphone.message}
                    label='Celular'
                    onBlur={() => triggerValidation('cellphone')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  {specialties && (
                    <Autocomplete
                      id='specialty'
                      options={specialties}
                      value={watchSpecialty || ''}
                      getOptionLabel={(option) => option.name || option}
                      onChange={(e, v) => {
                        setValue('specialty', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='specialty'
                          {...params}
                          error={!!errors.specialty}
                          helperText={
                            errors.specialty && errors.specialty.message
                          }
                          label='Especialidad'
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Box>
                <Box className={classes.inputBox}>
                  {providers ? (
                    <Autocomplete
                      id='provider'
                      options={providers}
                      value={watchProvider || ''}
                      getOptionLabel={(option) => option.name || option}
                      onChange={(e, v) => {
                        setValue('provider', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          disabled={!!providers}
                          name='provider'
                          {...params}
                          error={!!errors.provider}
                          helperText={
                            errors.provider && errors.provider.message
                          }
                          label='Médico Especialista'
                          fullWidth
                        />
                      )}
                    />
                  ) : (
                    <TextField disabled label='Médico Especialista' fullWidth />
                  )}
                </Box>
                <Box className={classes.inputBox}>
                  {delegations && (
                    <Autocomplete
                      id='delegation'
                      options={delegations}
                      disabled={!!sessionUser.delegation}
                      value={watchDelegation || ''}
                      getOptionLabel={(option) => option.name || option}
                      onChange={(e, v) => {
                        setValue('delegation', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='delegation'
                          disabled={!!sessionUser.delegation}
                          {...params}
                          error={!!errors.delegation}
                          helperText={
                            errors.delegation && errors.delegation.message
                          }
                          label='Delegación de origen'
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Box>

                {error && (
                  <Box mt={3}>
                    <Alert severity='error'>
                      <AlertTitle>Error al enviar la información</AlertTitle>
                      {error}
                    </Alert>
                  </Box>
                )}
                <Box mt={4} textAlign='center'>
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    loading={loading}
                  >
                    ENVIAR
                  </LoadingButton>
                </Box>
                <Box mt={2} textAlign='center'>
                  <Button component='a' href='/login'>
                    VOLVER
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
      <Dialog
        open={success}
        onClose={() => setSuccess(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Enviado exitosamente</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Se ha enviado la informacion correctamente
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='primary' component='a' href='login'>
            Ir a Iniciar Sesión
          </Button>
          <Button
            color='primary'
            autoFocus
            variant='contained'
            onClick={() => setSuccess(false)}
          >
            Cargar otra solicitud
          </Button>
        </DialogActions>
      </Dialog>
    </BackgroundImage>
  )
}

export default Request
