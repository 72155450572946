import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Login from './components/login'
import Request from './components/request'
import Confirm from './components/confirm'

const Router = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/request'>
          <Request />
        </Route>
        <Route path='/confirm'>
          <Confirm />
        </Route>
        <Route path='/'>
          <Login />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Router
