import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Controller } from 'react-hook-form'

export default function IconTextField({ icon, name, errors, type, ...props }) {
  return (
    <Grid
      container
      spacing={1}
      alignItems={errors[name] ? 'center' : 'flex-end'}
      justify='center'
    >
      <Grid item>
        {icon}
      </Grid>
      <Grid item xs={10} md={6}>
        <Controller
          name={name}
          error={!!errors[name]}
          helperText={errors[name] && errors[name].message}
          {...props}
          as={<TextField type={type} />}
        />
      </Grid>
    </Grid>
  )
}

IconTextField.defaultProps = {
  type: 'text',
  errors: {}
}

IconTextField.propTypes = {
  icon: PropTypes.node.isRequired,
  type: PropTypes.string,
  errors: PropTypes.shape({}),
}