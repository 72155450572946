import React, { useState, useEffect } from 'react'
import * as yup from 'yup'
import * as R from 'ramda'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  Grid,
  Button,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { Person, Lock } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import { BackgroundImage, IconTextField } from 'common/components'

import background from 'assets/94772307-doctor-in-hospital-background-with-copy-space-healthcare-and-medical-concept-.png'
import logo from 'assets/TeleMeditar_Logo.png'

import useSessionStorage from 'common/utils/useSessionStorage'
import USER_SESSION_KEY from 'common/utils/constants'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  loginCard: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  loginCardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}))

const users = {
  request: { password: 'request', href: 'request' },
  confirm: { password: 'confirm', href: 'confirm' },
  teleconsultaespecialista: { password: 'Form321', href: 'confirm' },
  'cafayate@ipssalta.gov.ar': {
    password: 'IPSCAFAYATE',
    href: 'request',
    delegation: '25634ec0-041d-11eb-b12e-ffad049bd9df',
  },
  'gralguemes@ipssalta.gov.ar': {
    password: 'IPSGUEMES',
    href: 'request',
    delegation: '2de9ecc0-041d-11eb-b12e-ffad049bd9df',
  },
  'jvgonzalez@ipssalta.gov.ar': {
    password: 'IPSJOAQUIN',
    href: 'request',
    delegation: '3680de70-041d-11eb-b12e-ffad049bd9df',
  },
  'metan@ipssalta.gov.ar': {
    password: 'IPSMETAN',
    href: 'request',
    delegation: '3ea22af0-041d-11eb-b12e-ffad049bd9df',
  },
  'oran@ipssalta.gov.ar': {
    password: 'IPSORAN',
    href: 'request',
    delegation: '55a19e70-041d-11eb-b12e-ffad049bd9df',
  },
  'rdelafrontera@ipssalta.gov.ar': {
    password: 'IPSROSARIO',
    href: 'request',
    delegation: '5e879a80-041d-11eb-b12e-ffad049bd9df',
  },
  'tartagal@ipssalta.gov.ar': {
    password: 'IPSTARTAGAL',
    href: 'request',
    delegation: '6b3cc700-041d-11eb-b12e-ffad049bd9df',
  },
}

const Login = () => {
  const classes = useStyles()
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState(null)
  const [sessionUser, setSessionUser] = useSessionStorage(USER_SESSION_KEY)

  useEffect(() => {
    setSessionUser(null)
  }, [])

  const schema = yup.object().shape({
    password: yup.string().required('Este campo es requerido'),
    username: yup.string().required('Este campo es requerido'),
  })

  const { control, errors, handleSubmit, triggerValidation } = useForm({
    defaultValues: {
      password: '',
      username: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = ({ username, password }) => {
    if (!users[username] || !R.equals(users[username].password, password)) {
      setErrorMessage('Las credenciales son inválidas')
      return
    }
    setSessionUser(users[username])
    history.push(users[username].href)
  }

  return (
    <BackgroundImage image={background}>
      <Grid container className={classes.container}>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          <Card className={classes.loginCard}>
            <CardMedia className={classes.logo} image={logo} />
            <CardContent className={classes.loginCardContent}>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <IconTextField
                    icon={<Person />}
                    control={control}
                    name='username'
                    errors={errors}
                    label='Usuario'
                    onBlur={() => triggerValidation('username')}
                    fullWidth
                  />
                </Box>
                <Box mt={2}>
                  <IconTextField
                    icon={<Lock />}
                    control={control}
                    name='password'
                    errors={errors}
                    label='Contraseña'
                    onBlur={() => triggerValidation('password')}
                    fullWidth
                    type='password'
                  />
                </Box>
                {errorMessage && (
                  <Box mt={3}>
                    <Alert severity='error'>
                      <AlertTitle>Error al intentar iniciar sesión</AlertTitle>
                      {errorMessage}
                    </Alert>
                  </Box>
                )}
                <Box mt={4} textAlign='center'>
                  <Button type='submit' variant='contained' color='primary'>
                    INICIAR SESIÓN
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
    </BackgroundImage>
  )
}

export default Login
