import React from 'react'
import ReactDOM from 'react-dom'
import 'moment/locale/es'
import moment from 'moment'

import { ThemeProvider } from 'styled-components'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from 'common/theme'

import Router from './Router'
import * as serviceWorker from './serviceWorker'
import 'fontsource-roboto'
import 'index.css'

moment.locale('es')

const render = () => {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>,
    document.getElementById('root')
  )
}

render()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
