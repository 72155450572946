import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  container: {
    display: 'inline-block',
  },
}))

export default function LoadingButton({
  loading,
  disabled,
  children,
  ...props
}) {
  const classes = useStyles()

  return (
    <Box position='relative' className={classes.container}>
      <Button {...props} disabled={loading || disabled}>
        {children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </Box>
  )
}

LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}
