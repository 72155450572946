import styled from 'styled-components'
import Box from '@material-ui/core/Box'

const BackgroundImage = styled(Box)`
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
`

export default BackgroundImage
