import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import moment from 'moment'
import axios from 'axios'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  Box,
  Card,
  CardMedia,
  CardContent,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { BackgroundImage, LoadingButton } from 'common/components'

import Autocomplete from '@material-ui/lab/Autocomplete'
import ascByName from 'common/utils/sort'

import background from 'assets/94772307-doctor-in-hospital-background-with-copy-space-healthcare-and-medical-concept-.png'
import logo from 'assets/TeleMeditar_Logo.png'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  card: {
    padding: theme.spacing(3),
    minHeight: '50vh',
  },
  logo: {
    backgroundSize: 'contain',
    height: '10vh',
    [theme.breakpoints.up('md')]: {
      height: '20vh',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),

    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(6),
    },
  },
  inputBox: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
  },
  fullWidth: {
    width: '100%',
  },
}))

const Confirm = () => {
  const classes = useStyles()

  const [specialistProviders, setSpecialistProviders] = useState(null)
  const [consultingProviders, setConsultingProviders] = useState(null)
  const [delegations, setDelegations] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    axios(`${process.env.REACT_APP_API_URL}/provider/specialist`, {
      method: 'GET',
    }).then((response) => {
      setSpecialistProviders(ascByName(response.data.items))
    })

    axios(`${process.env.REACT_APP_API_URL}/provider/consulting`, {
      method: 'GET',
    }).then((response) => {
      setConsultingProviders(ascByName(response.data.items))
    })

    axios(`${process.env.REACT_APP_API_URL}/delegation`, {
      method: 'GET',
    }).then((response) => {
      setDelegations(ascByName(response.data.items))
    })
  }, [])

  const schema = yup.object().shape({
    name: yup.string().required('Este campo es requerido'),
    lastName: yup.string().required('Este campo es requerido'),
    document: yup.string().required('Este campo es requerido'),
    username: yup.string().required('Este campo es requerido'),
    password: yup.string().required('Este campo es requerido'),
    date: yup
      .mixed()
      .required('Este campo es requerido')
      .test('is-in-the-past', 'La fecha no puede ser en el pasado', (value) => {
        return moment().diff(moment(value, 'YYYY/MM/DD'), 'day') <= 0
      }),
    time: yup.string().required('Este campo es requerido'),
    specialistProvider: yup
      .object()
      .required('Este campo es requerido')
      .nullable(),
    consultingProvider: yup
      .object()
      .required('Este campo es requerido')
      .nullable(),
    delegation: yup.object().required('Este campo es requerido').nullable(),
  })

  const {
    control,
    errors,
    handleSubmit,
    triggerValidation,
    register,
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      lastName: '',
      document: '',
      username: '',
      password: '',
      date: '',
      time: '',
      specialistProvider: null,
      consultingProvider: null,
      delegation: null,
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    register('specialistProvider')
    register('consultingProvider')
    register('delegation')
  }, [register])

  const onSubmit = (data) => {
    setLoading(true)
    setError(null)
    axios(`${process.env.REACT_APP_API_URL}/appointment/give`, {
      method: 'POST',
      data: {
        ...data,
        delegation: data.delegation.id,
        specialistProvider: data.specialistProvider.id,
        consultingProvider: data.consultingProvider.id,
      },
    })
      .then(() => {
        setSuccess(true)
        reset()
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const watchDelegation = watch('delegation')
  const watchConsultingProvider = watch('consultingProvider')
  const watchSpecialistProvider = watch('specialistProvider')

  return (
    <BackgroundImage image={background}>
      <Grid container className={classes.container}>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          <Card className={classes.card}>
            <CardMedia className={classes.logo} image={logo} />
            <CardContent className={classes.cardContent}>
              <form
                noValidate
                onSubmit={handleSubmit(onSubmit)}
                autoComplete='off'
              >
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='name'
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                    label='Nombre'
                    onBlur={() => triggerValidation('name')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='lastName'
                    error={!!errors.lastName}
                    helperText={errors.lastName && errors.lastName.message}
                    label='Apellido'
                    onBlur={() => triggerValidation('lastName')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='document'
                    error={!!errors.document}
                    helperText={errors.document && errors.document.message}
                    label='Documento'
                    onBlur={() => triggerValidation('document')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='username'
                    error={!!errors.username}
                    helperText={errors.username && errors.username.message}
                    label='Usuario en TeleMeditar'
                    onBlur={() => triggerValidation('username')}
                    fullWidth
                    as={<TextField />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  <Controller
                    control={control}
                    name='password'
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                    label='Contraseña en TeleMeditar'
                    onBlur={() => triggerValidation('password')}
                    fullWidth
                    as={<TextField type='password' />}
                  />
                </Box>
                <Box className={classes.inputBox}>
                  {specialistProviders && (
                    <Autocomplete
                      id='specialistProvider'
                      options={specialistProviders}
                      value={watchSpecialistProvider || ''}
                      getOptionLabel={(option) => option.name || option}
                      onChange={(e, v) => {
                        setValue('specialistProvider', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='specialistProvider'
                          {...params}
                          error={!!errors.specialistProvider}
                          helperText={
                            errors.specialistProvider &&
                            errors.specialistProvider.message
                          }
                          label='Médico Especialista'
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Box>
                <Box className={classes.inputBox}>
                  <FormControl className={classes.fullWidth}>
                    <Controller
                      control={control}
                      name='date'
                      error={!!errors.date}
                      helperText={errors.date && errors.date.message}
                      label='Fecha del Turno'
                      onBlur={() => triggerValidation('date')}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      as={<TextField type='date' />}
                    />
                  </FormControl>
                </Box>
                <Box className={classes.inputBox}>
                  <FormControl className={classes.fullWidth}>
                    <Controller
                      control={control}
                      name='time'
                      error={!!errors.time}
                      helperText={errors.time && errors.time.message}
                      label='Hora del Turno'
                      onBlur={() => triggerValidation('time')}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      as={<TextField type='time' />}
                    />
                  </FormControl>
                </Box>

                <Box className={classes.inputBox}>
                  {delegations && (
                    <Autocomplete
                      id='delegation'
                      options={delegations}
                      value={watchDelegation || ''}
                      getOptionLabel={(option) => option.name || option}
                      onChange={(e, v) => {
                        setValue('delegation', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='delegation'
                          {...params}
                          error={!!errors.delegation}
                          helperText={
                            errors.delegation && errors.delegation.message
                          }
                          label='Delegación de destino'
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Box>
                <Box className={classes.inputBox}>
                  {consultingProviders && (
                    <Autocomplete
                      id='consultingProvider'
                      options={consultingProviders}
                      value={watchConsultingProvider || ''}
                      getOptionLabel={(option) => option.name || option}
                      onChange={(e, v) => {
                        setValue('consultingProvider', v, true)
                      }}
                      renderInput={(params) => (
                        <TextField
                          name='consultingProvider'
                          {...params}
                          error={!!errors.consultingProvider}
                          helperText={
                            errors.consultingProvider &&
                            errors.consultingProvider.message
                          }
                          label='Médico Consultor'
                          fullWidth
                        />
                      )}
                    />
                  )}
                </Box>
                {error && (
                  <Box mt={3}>
                    <Alert severity='error'>
                      <AlertTitle>Error al enviar la información</AlertTitle>
                      {error}
                    </Alert>
                  </Box>
                )}
                <Box mt={4} textAlign='center' position='relative'>
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    color='primary'
                    loading={loading}
                  >
                    ENVIAR
                  </LoadingButton>
                </Box>
                <Box mt={2} textAlign='center'>
                  <Button component='a' href='/login'>
                    VOLVER
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
      <Dialog
        open={success}
        onClose={() => setSuccess(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Enviado exitosamente</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Se ha enviado la informacion correctamente
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='primary' component='a' href='login'>
            Ir a Iniciar Sesión
          </Button>
          <Button
            color='primary'
            autoFocus
            variant='contained'
            onClick={() => setSuccess(false)}
          >
            Cargar otra solicitud
          </Button>
        </DialogActions>
      </Dialog>
    </BackgroundImage>
  )
}

export default Confirm
